/* src/global.css */
html {
    overflow-y: scroll;
}

/* Style for webkit browsers */
::-webkit-scrollbar {
    width: 8px; /* Width of the scrollbar */
    height: 8px; /* Height of the scrollbar (for horizontal scrollbar) */
}

/* Track */
::-webkit-scrollbar-track {
    background: transparent; /* Color of the scrollbar track */
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgba(211, 211, 211, 0.8); /* Light gray color for the scrollbar handle */
    border-radius: 10px; /* Rounded corners for the scrollbar handle */
    border: 2px solid transparent; /* Space around the scrollbar handle */
    background-clip: padding-box; /* Prevents the border from covering the rounded corners */
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: rgba(169, 169, 169, 0.8); /* Color of the scrollbar handle on hover */
}

/* Style for Firefox */
* {
    scrollbar-width: thin; /* Thin scrollbar */
    scrollbar-color: rgba(211, 211, 211, 0.8) transparent; /* Scrollbar colors */
}

/* Additional styles for Firefox */
*::-webkit-scrollbar-thumb {
    border-radius: 10px;
}