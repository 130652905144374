@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Text:wght@400;500;700&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* The scrollbar track (the part the scrollbar sits in) */
.custom-scrollbar::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

/* The scrollbar handle */
.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #9c9c9c;
  border-radius: 4px;
}

/* Handle on hover */
.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #707070;
}

/* The scrollbar itself */
.custom-scrollbar::-webkit-scrollbar {
  width: 0px; /* width of the entire scrollbar */
  background: transparent;
  height: 8px;
}

.custom-scrollbar:hover::-webkit-scrollbar {
  width: 8px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #9c9c9c;
}

.focused-cell {
  background-color: #c7d2fe; /* Equivalent to bg-indigo-200 */
}

.row-hover:hover td:not(.focused-cell) {
  background-color: #f3f4f6; /* Equivalent to bg-gray-100 */
}

.row-hover td:hover:not(.focused-cell) {
  background-color: #E0E7FF; /* Equivalent to bg-indigo-100 */
}
